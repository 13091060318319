<template>
  <!--    <pre><code>{{ JSON.stringify(protocol, null, 2) }}</code></pre>-->
  <div class="tabs-material-subtitle">
    <Container>
      <h2>Informations générales</h2>
    </Container>
  </div>

  <div class="key-values key-values--page-bottom">
    <KeyValue table label="Titre" :value="protocol.titre" />
    <KeyValue
      table
      label="Année de récolte"
      :value="protocol.annee_recolte?.valeur"
    />
    <KeyValue table label="Cultures">
      <template v-if="protocol.cultures.length > 0">
        <div class="tags">
          <MiniTag
            v-for="culture in protocol.cultures"
            :key="culture.id"
            :id="culture.uid"
            :text="culture.nom"
          />
        </div>
      </template>
      <template v-else>
        <span></span>
      </template>
    </KeyValue>
    <KeyValue
      table
      label="Thématique"
      :value="protocol.thematique?.designation"
    />
        <KeyValue table label="Sous-thèmes">
      <template v-if="protocol.sous_thematiques.length > 0">
        <div class="tags">
          <MiniTag
            v-for="sous_thematique in protocol.sous_thematiques"
            :key="sous_thematique.id"
            :id="sous_thematique.uid"
            :text="sous_thematique.nom"
          />
        </div>
      </template>
      <template v-else>
        <span></span>
      </template>
    </KeyValue>
    <KeyValue table label="Objectif" :value="protocol.objectifs" />
    <KeyValue table label="Type protocole" :value="protocol.type?.designation" />
    <KeyValue
      table
      label="Essai BPE"
      :value="helperService.displayBoolean(protocol.bpe)"
    />
    <KeyValue
      table
      label="Essai en prestation de service"
      :value="helperService.displayBoolean(protocol.prestation_service)"
    />
    <KeyValue
      table
      label="Essai AB"
      :value="helperService.displayBoolean(protocol.agriculture_biologique)"
    />
    <KeyValue table label="Code national" :value="protocol.code_national" />
    <KeyValue
      table
      child
      label="Qualification"
      :value="protocol.national_qualification"
    />
    <KeyValue table child label="Type" :value="protocol.type_qualification" />
    <KeyValue
      table
      child
      label="Objectif"
      :value="protocol.objectif_qualification"
    />
    <KeyValue table label="Code régional" :value="protocol.code_regional" />
    <KeyValue
      table
      label="Code donneur d'ordre"
      :value="protocol.donneur_ordre_code"
    />
    <KeyValue table label="Code entité" :value="protocol.code_entite" />
    <KeyValue
      table
      label="Donneur d'ordre (nom de la structure)"
      :value="protocol.donneur_ordre"
    />
    <KeyValue
      table
      label="Contact donneur d'ordre"
      :value="protocol.donneur_ordre_contact"
    />
    <KeyValue table label="N°devis" :value="protocol.numero_devis" />
  </div>
</template>

<script>
import KeyValue from '@/components/form/KeyValue.vue'
import MiniTag from '@/components/base/MiniTag.vue'
import Container from '@/components/layout/Container.vue'

export default {
  name: 'ProtocolSubItemGeneralInformation',

  components: {
    KeyValue,
    MiniTag,
    Container,
  },

  props: {
    pageTitle: {
      type: String,
    },
    protocol: {
      type: Object,
    },
  },
}
</script>

<style scoped>
</style>
